.list-item {
  transition: background 300ms;
  font-size: 1.3em;
  /* margin-left: .75em; */
}

.list-item .header {
  padding-bottom: 0.25em;
}

.ui.list > .item {
  padding-left: 0.75em;
}

.list-item:hover {
  background: #eee;
  cursor: pointer;
}

.family .list-item.purchased,
.me .list-item.purchased {
  opacity: 0.4;
}

.me .list-item.is-recipient {
  opacity: 1;
}
